import { useEffect } from "react";
import { toast } from "react-toastify";

/**
 * Displays a global error toast based on the provided error or data from loader or action.
 * @param dataOrError Can be a string, loaderData, or actionData.
 */
export const useToastError = (dataOrError: unknown) => {
  useEffect(() => {
    if (!dataOrError) return;

    if (typeof dataOrError === "string") {
      toast.error(dataOrError);
    }

    if (
      typeof dataOrError === "object" &&
      "globalError" in dataOrError &&
      dataOrError.globalError &&
      typeof dataOrError.globalError === "string" &&
      dataOrError?.globalError.length > 0
    ) {
      toast.error(dataOrError.globalError);
    }
  }, [dataOrError]);
};
